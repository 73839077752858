import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import React from "react";
import { SubHeroItemProps } from "./props";
import { classes, StyledGrid } from "./styles";

const SubHeroItem = (props: SubHeroItemProps) => {
  const { src, text, alt, imgWidth, imgHeight } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledGrid
      container
      item
      sm={12}
      md={4}
      direction="column"
      alignItems="center"
      className={clsx({ [classes.gutters]: isMobile })}
    >
      <img
        width={imgWidth}
        height={imgHeight}
        className={classes.illustration}
        src={src}
        alt={alt || text}
      />
      <Typography
        variant="h6"
        component="h2"
        align="center"
        className={classes.typo}
      >
        {text}
      </Typography>
    </StyledGrid>
  );
};

export default SubHeroItem;
