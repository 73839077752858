import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import SectionTitle from "../../../components/layout/SectionTitle";
import { HOME_SUB_HERO_SECTION } from "../../../util/constants";
import { SubHeroProps } from "./props";
import { classes, StyledSectionWrapper } from "./styles";
import SubHeroItem from "./SubHeroItem";

const SubHero = (props: SubHeroProps) => {
  return (
    <StyledSectionWrapper padding="large" sectionId={HOME_SUB_HERO_SECTION}>
      <Container>
        <SectionTitle text="Innovance Solutions" />
        <Grid container>
          <SubHeroItem
            src="/images/agenda/innovance-solutions-agenda-suivre-les-temps-de-travail.svg"
            text="Suivi des temps"
            imgWidth="579"
            imgHeight="564"
          />
          <SubHeroItem
            src="/images/budget/innovance-solutions-budget-suivi-budgetaire.svg"
            text="Gestion budgétaire"
            imgWidth="784"
            imgHeight="734"
          />
          <SubHeroItem
            src="/images/innovance-solutions-accompagnement-et-expertise.svg"
            text="Formation & accompagnement"
            imgWidth="951"
            imgHeight="428"
          />
        </Grid>
        <Grid container className={classes.paragraph}>
          <Typography fontSize={22} align="center">
            Fonctionnalité centrale de notre logiciel de gestion d'association :
            connecter les temps de votre équipe salariée avec les données de
            gestion issues des logiciels utilisés pour votre comptabilité et
            votre paie. Depuis plus de 10 ans nous répondons ainsi à des
            logiques et des problématiques de gestion spécifiques aux
            associations aussi bien pour rendre compte des financements publics
            que pour piloter en temps réel les ressources humaines et
            financières.
          </Typography>
        </Grid>
      </Container>
    </StyledSectionWrapper>
  );
};

export default SubHero;
