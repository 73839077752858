import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import React from "react";
import { MdChat, MdRemoveRedEye } from "react-icons/md";
import HeroSubtitle from "../../../components/HeroSubtitle";
import HeroTitle from "../../../components/HeroTitle";
import { globalClasses } from "../../../components/layout/MainLayout/styles";
import { handleNavigate } from "../../../util";
import {
  HOME_HERO_SECTION,
  HOME_PRODUCTS_SECTION,
} from "../../../util/constants";
import { HeroProps } from "./props";
import { classes, StyledSectionWrapper } from "./styles";

const Hero = (props: HeroProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledSectionWrapper
      padding="normal"
      isMobile={isMobile}
      sectionId={HOME_HERO_SECTION}
      className={globalClasses.homeHeroGradient}
    >
      <div className={classes.background}>
        <Container>
          <Grid
            item
            sm={12}
            md={6}
            justifyContent={isMobile ? "center" : "flex-start"}
            container
          >
            <HeroTitle>
              Le logiciel de gestion d'association qui vous fait vraiment gagner
              du temps
            </HeroTitle>
            <HeroSubtitle>
              Innovance simplifie la vie des associations employeuses,
              multi-actions et multi-financeurs qui doivent justifier de leurs
              financements et qui souhaitent professionnaliser leur organisation
            </HeroSubtitle>
            <div
              className={clsx(classes.actions, {
                [classes.xsActions]: isXs,
              })}
            >
              <Button
                onClick={() => handleNavigate(`/#${HOME_PRODUCTS_SECTION}`)}
                startIcon={<MdRemoveRedEye />}
                size={isMobile ? "medium" : "large"}
                className={classes.button}
                aria-label="Découvrir"
              >
                Découvrir nos solutions
              </Button>
              <Button
                onClick={() => handleNavigate("/contact")}
                startIcon={<MdChat />}
                size={isMobile ? "medium" : "large"}
                className={classes.button}
                aria-label="Prendre contact"
              >
                Demander une démo
              </Button>
            </div>
          </Grid>
        </Container>
      </div>
    </StyledSectionWrapper>
  );
};

export default Hero;
