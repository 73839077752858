import Grid from "@mui/material/Grid";
import styled from "../../../../util/styled";

const PREFIX = "SubHeroItem";

export const classes = {
  illustration: `${PREFIX}-illustration`,
  gutters: `${PREFIX}-gutters`,
  typo: `${PREFIX}-typo`,
};

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.illustration}`]: {
    maxWidth: "100%",
    height: 180,
  },

  [`&.${classes.gutters}`]: {
    margin: theme.spacing(2, 0),
    "&:first-of-type": {
      marginTop: 0,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },

  [`& .${classes.typo}`]: {
    marginTop: theme.spacing(2),
    fontWeight: 600,
  },
}));
