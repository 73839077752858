import SectionWrapper from "../../../components/layout/SectionWrapper";
import styled from "../../../util/styled";

const PREFIX = "Products";

export const classes = {
  container: `${PREFIX}-container`,
  asterisk: `${PREFIX}-asterisk`,
};

export const StyledSectionWrapper = styled(SectionWrapper)(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.asterisk}`]: {
    fontSize: theme.typography.caption.fontSize,
    fontStyle: "italic",
  },
}));
