import Grid from "@mui/material/Grid";
import styled from "../../../../util/styled";

const PREFIX = "ProductRow";

export const classes = {
  image: `${PREFIX}-image`,
  title: `${PREFIX}-title`,
  gutterBottom: `${PREFIX}-gutterBottom`,
  listItem: `${PREFIX}-listItem`,
  listItemIcon: `${PREFIX}-listItemIcon`,
  textRight: `${PREFIX}-textRight`,
  textNoGrow: `${PREFIX}-textNoGrow`,
  button: `${PREFIX}-button`,
};

export const StyledGrid = styled(Grid)<{ title: string }>(
  ({ theme, title }) => {
    const titleColor = title.includes("Agenda")
      ? theme.palette.iaBlue
      : theme.palette.ibPurple;
    const buttonColor = title.includes("Agenda")
      ? theme.palette.iaPink
      : theme.palette.ibGreen;

    return {
      margin: theme.spacing(5, 0),

      [`& .${classes.image}`]: {
        width: "100%",
        height: 300,
      },

      [`& .${classes.title}`]: {
        fontWeight: 600,
        color: titleColor.main,
      },

      [`& .${classes.gutterBottom}`]: {
        marginBottom: theme.spacing(3),
      },

      [`& .${classes.listItem}`]: {
        flexDirection: "row-reverse",
      },

      [`& .${classes.listItemIcon}`]: {
        color: theme.palette.innoGrey.main,
      },

      [`& .${classes.textRight}`]: {
        textAlign: "right",
      },

      [`& .${classes.textNoGrow}`]: {
        flexGrow: 0,
      },

      [`& .${classes.button}`]: {
        color: buttonColor.contrastText,
        transition: theme.transitions.create("all"),
        backgroundColor: buttonColor.main,
        "&:hover": {
          opacity: 0.75,
          color: buttonColor.dark,
          backgroundColor: buttonColor.light,
        },
      },
    };
  }
);
