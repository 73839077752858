import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import SectionTitle from "../../../components/layout/SectionTitle";
import {
  HOME_AGENDA_ROW,
  HOME_BUDGET_ROW,
  HOME_PRODUCTS_SECTION,
} from "../../../util/constants";
import ProductRow from "./ProductRow";
import { StyledSectionWrapper, classes } from "./styles";

const Products = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledSectionWrapper sectionId={HOME_PRODUCTS_SECTION}>
      <Container className={classes.container}>
        <SectionTitle text="Un logiciel de gestion adapté aux besoins de votre association" />
        <ProductRow
          rowId={HOME_AGENDA_ROW}
          alignement={isMobile ? "center" : "left"}
          direction={isMobile ? "column" : "row"}
          imgSrc="/images/agenda/innovance-solutions-agenda-suivre-les-temps-de-travail.svg"
          title="Innovance Agenda"
          text="Une solution de suivi des temps spécialisée pour les associations qui vous permet de :"
          bullets={[
            "Rendre compte aux financeurs",
            "Suivre les temps de travail",
            "Piloter un plan de charge",
            "Collaborer et réduire les contraintes de vos utilisateurs",
            <React.Fragment>
              Répartir les charges salariales et indirectes sur les actions
              <br />
              <span className={classes.asterisk}>
                (Couplez l’agenda avec Innovance Budget)
              </span>
            </React.Fragment>,
          ]}
          imgWidth="579"
          imgHeight="564"
          link="agenda"
        />
        <ProductRow
          rowId={HOME_BUDGET_ROW}
          alignement={isMobile ? "center" : "left"}
          direction={isMobile ? "column" : "row-reverse"}
          imgSrc="/images/budget/innovance-solutions-budget-suivi-budgetaire.svg"
          title="Innovance Budget"
          text="Une solution de pilotage en temps réel des budgets de chaque action de l’association qui vous permet de :"
          bullets={[
            "Valoriser la comptabilité et l’enrichir de la répartition analytique des charges salariales et de l’indirect",
            "Faciliter le suivi budgétaire tout au long de l’année",
            "Instaurer un dialogue de gestion entre responsable d’un projet, direction et responsable administratif et financier pour un meilleur suivi budgétaire des actions ",
            "Gagner du temps pour les comptes rendus aux financeurs",
          ]}
          imgWidth="784"
          imgHeight="734"
          link="budget"
        />
      </Container>
    </StyledSectionWrapper>
  );
};

export default Products;
