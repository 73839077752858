import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import React from "react";
import SectionTitle from "../../../components/layout/SectionTitle";
import { HOME_CONTACT_SECTION } from "../../../util/constants";
import { handleNavigate } from "../../../util";
import { classes, StyledSectionWrapper } from "./styles";

const Contact = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledSectionWrapper sectionId={HOME_CONTACT_SECTION}>
      <Container className={classes.container}>
        <SectionTitle text="Allons plus loin ensemble" />
        <Grid container>
          <Grid
            item
            container
            sm={12}
            md={8}
            alignItems="center"
            justifyContent={isMobile ? "center" : "flex-start"}
            className={clsx(classes.gridItem, {
              [classes.mobileGridItem]: isMobile,
            })}
          >
            <Typography
              variant="h4"
              component="h2"
              align={isMobile ? "center" : "inherit"}
              className={classes.text}
            >
              Contactez-nous pour plus de détails et pour une démonstration de
              nos solutions !
            </Typography>
          </Grid>
          <Grid
            item
            container
            sm={12}
            md={4}
            alignItems="center"
            justifyContent={isMobile ? "center" : "flex-end"}
            className={clsx(classes.gridItem, {
              [classes.mobileGridItem]: isMobile,
            })}
          >
            <Button
              aria-label="Je prends contact !"
              onClick={() => handleNavigate("/contact")}
              variant="contained"
              size="large"
              className={classes.button}
            >
              Je prends contact
            </Button>
          </Grid>
        </Grid>
      </Container>
    </StyledSectionWrapper>
  );
};

export default Contact;
