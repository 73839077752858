import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import React from "react";
import {
  MdNewReleases,
  MdPeople,
  MdPermPhoneMsg,
  MdVerifiedUser,
} from "react-icons/md";
import SectionTitle from "../../../components/layout/SectionTitle";
import Link from "../../../components/Link";
import ServiceItem from "../../../components/ServiceItem";
import { HOME_SERVICES_SECTION, QUALIOPI_HREF } from "../../../util/constants";
import { classes, StyledSectionWrapper } from "./styles";

const Services = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledSectionWrapper sectionId={HOME_SERVICES_SECTION}>
      <Container className={classes.container}>
        <SectionTitle text="L’humain au centre de notre qualité de service" />
        <Grid container direction="column">
          <Grid container>
            <ServiceItem
              verticalDivier
              title="La formation"
              icon={MdVerifiedUser}
              text={
                <React.Fragment>
                  Innovance est un organisme de formation, certifié{" "}
                  <MuiLink
                    underline="hover"
                    className={classes.link}
                    target="_blank"
                    rel="noopener"
                    href={QUALIOPI_HREF}
                  >
                    Qualiopi
                  </MuiLink>
                  , qui propose de nombreux modules pour permettre aux
                  participants de s’approprier les solutions, de bien les
                  paramétrer et d’exploiter au mieux leurs fonctionnalités. Ces
                  formations, assurées par notre équipe, sont opérationnelles et
                  adaptées aux particularités et besoins de votre organisation.{" "}
                  <Link
                    underline="hover"
                    className={classes.link}
                    rel="noopener"
                    href="/formation/qualite-de-nos-formations"
                  >
                    Découvrez les programmes et les indicateurs de qualité de
                    nos formations.
                  </Link>
                </React.Fragment>
              }
              color="blue"
            />
            <ServiceItem
              icon={MdPeople}
              title="L’accompagnement"
              text="Innovance propose son expertise pour maximiser les possibilités offertes par ses solutions. Nous pouvons vous accompagner dans l’exploitation des données saisies dans nos logiciels de gestion associative à l’aide de la fonctionnalité tableaux croisés dynamiques de Microsoft Excel©."
              color="deepPurple"
            />
          </Grid>
          {!isMobile && (
            <Divider
              className={clsx(classes.divider, { [classes.hide]: isMobile })}
              variant="fullWidth"
            />
          )}
          <Grid container>
            <ServiceItem
              verticalDivier
              icon={MdPermPhoneMsg}
              title="L’assistance téléphonique"
              text="Innovance propose une assistance 5 jours sur 7 lorsque vous rencontrez des difficultés dans l’utilisation de nos solutions. Vous serez toujours en relation avec un membre qualifié de notre équipe pour vous apporter une réponse ou une solution dans un délai maximal de 48h."
              color="orange"
            />
            <ServiceItem
              icon={MdNewReleases}
              title="La maintenance évolutive"
              text="Innovance s’attache à améliorer ses solutions de manière continue et à corriger les dysfonctionnements pour vous offrir une expérience d’utilisation optimale. Ces évolutions sont le fruit des retours des utilisateurs et de notre travail d’équipe quotidien, dans le soucis de répondre au mieux à l’évolution des besoins des utilisateurs."
              color="amber"
            />
          </Grid>
        </Grid>
      </Container>
    </StyledSectionWrapper>
  );
};

export default Services;
