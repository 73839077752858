import SectionWrapper from "../../../components/layout/SectionWrapper";
import styled from "../../../util/styled";

const PREFIX = "SubHero";

export const classes = {
  paragraph: `${PREFIX}-paragraph`,
};

export const StyledSectionWrapper = styled(SectionWrapper)(({ theme }) => ({
  backgroundColor: "transparent",

  [`& .${classes.paragraph}`]: {
    marginTop: theme.spacing(4),
    color: theme.palette.innoGrey.main,
  },
}));
