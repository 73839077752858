import SectionWrapper from "../../../components/layout/SectionWrapper";
import styled from "../../../util/styled";

const PREFIX = "Contact";

export const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  gridItem: `${PREFIX}-gridItem`,
  mobileGridItem: `${PREFIX}-mobileGridItem`,
  text: `${PREFIX}-text`,
  button: `${PREFIX}-button`,
};

export const StyledSectionWrapper = styled(SectionWrapper)(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.gridItem}`]: {
    margin: theme.spacing(5, 0),
  },

  [`& .${classes.mobileGridItem}`]: {
    "&:first-of-type": {
      marginBottom: theme.spacing(2),
    },
    "&:last-child": {
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.text}`]: {
    flexGrow: 1,
  },

  [`& .${classes.button}`]: {
    transition: theme.transitions.create("opacity"),
    color: theme.palette.innoBlue.contrastText,
    backgroundColor: theme.palette.innoBlue.main,
    "&:hover": {
      color: theme.palette.innoBlue.dark,
      backgroundColor: theme.palette.innoBlue.light,
      opacity: 0.75,
    },
  },
}));
