import Button from "@mui/material/Button";
import Grid, { GridDirection } from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import React from "react";
import { MdStar } from "react-icons/md";
import { handleNavigate } from "../../../../util";
import { ProductRowProps } from "./props";
import { classes, StyledGrid } from "./styles";

interface AlignementMapType {
  [key: string]: {
    direction: GridDirection;
    alignItems: any;
    textAlign: string;
  };
}

const alignementMap: AlignementMapType = {
  left: {
    direction: "row",
    alignItems: "flex-start",
    textAlign: "left",
  },
  right: {
    direction: "row-reverse",
    alignItems: "flex-end",
    textAlign: "right",
  },
  center: {
    direction: "column",
    alignItems: "center",
    textAlign: "center",
  },
};

const ProductRow = (props: ProductRowProps) => {
  const {
    title,
    alignement,
    direction,
    text,
    imgSrc,
    bullets,
    rowId,
    imgHeight,
    imgWidth,
    link,
  } = props;

  const { alignItems, textAlign } = alignementMap[alignement];
  return (
    <StyledGrid
      id={rowId}
      container
      title={title}
      direction={direction}
      alignItems={alignement === "center" ? alignItems : undefined}
    >
      <Grid container item sm={5} alignItems="center">
        <img
          width={imgWidth}
          height={imgHeight}
          className={classes.image}
          src={imgSrc}
          alt={title}
        />
      </Grid>
      <Grid
        container
        item
        sm={12}
        md={7}
        direction="column"
        alignItems={alignItems}
      >
        <Typography
          variant="h6"
          component="h3"
          align={textAlign as any}
          className={clsx(classes.title, classes.gutterBottom)}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          component="div"
          align={textAlign as any}
          className={classes.gutterBottom}
        >
          {text}
          <List dense>
            {bullets.map((bullet, index) => (
              <ListItem
                key={`${bullet}-${index}`}
                className={clsx({ [classes.listItem]: alignement === "right" })}
              >
                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                  <MdStar />
                </ListItemIcon>
                <ListItemText
                  primary={bullet}
                  className={clsx({
                    [classes.textRight]: alignement === "right",
                    [classes.textNoGrow]: alignement === "center",
                  })}
                />
              </ListItem>
            ))}
          </List>
        </Typography>
        <Button
          onClick={() => handleNavigate(link)}
          className={classes.button}
          size="large"
          aria-label="Découvrir"
        >
          Je découvre !
        </Button>
      </Grid>
    </StyledGrid>
  );
};

export default ProductRow;
