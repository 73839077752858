import React from "react";
import Customers from "../components/CustomerSection";
import Seo from "../components/Seo";
import { clients } from "../data";
import Contact from "../views/Home/Contact";
import Hero from "../views/Home/Hero";
import Products from "../views/Home/Products";
import Services from "../views/Home/Services";
import SubHero from "../views/Home/SubHero";

const IndexPage = () => {
  return (
    <React.Fragment>
      <Seo title="Innovance Solutions" />
      <Hero />
      <SubHero />
      <Products />
      <Services />
      <Customers customers={clients} />
      <Contact />
    </React.Fragment>
  );
};

export default IndexPage;
